/* media breakpoints for Material Design */
/*
xs	'(max-width: 599px)'
gt-xs	'(min-width: 600px)'
sm	'(min-width: 600px) and (max-width: 959px)'
gt-sm	'(min-width: 960px)'
md	'(min-width: 960px) and (max-width: 1279px)'
gt-md	'(min-width: 1280px)'
lg	'(min-width: 1280px) and (max-width: 1919px)'
gt-lg	'(min-width: 1920px)'
xl	'(min-width: 1920px)'
*/


@mixin xs {
    @media (max-width: 599px) {
      @content;
    }
  }
  @mixin gt-xs {
    @media (min-width: 600px) {
      @content;
    }
  }
  @mixin sm {
    @media (min-width: 600px) and (max-width: 959px) {
      @content;
    }
  }
  @mixin gt-sm {
    @media (min-width: 960px) {
      @content;
    }
  }
  @mixin lt-md {
    @media (max-width: 959px) {
      @content;
    }
  }
  @mixin md {
    @media (min-width: 960px) and (max-width: 1279px) {
      @content;
    }
  }
  @mixin gt-md {
    @media (min-width: 1280px) {
      @content;
    }
  }
  @mixin lt-lg {
    @media (max-width: 1279px) {
      @content;
    }
  }
  @mixin lg {
    @media (min-width: 1280px) and (max-width: 1919px) {
      @content;
    }
  }
  @mixin gt-lg {
    @media (min-width: 1920px) {
      @content;
    }
  }
  @mixin xl {
    @media (min-width: 1920px) {
      @content;
    }
  }
  
  /* end of Material brakpoint mixin definitions. 
      usage: 
      @include xl {
      font-size: 18px;
    }
  */
  