// Custom Theming for Angular Material
@use '@angular/material' as mat;
// For more information: https://material.angular.io/guide/theming
// Plus imports for other components in your app.
@import '@angular/material/theming';
@import 'scss/ecotel-colors';
@import 'scss/variables_medion';
// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// $custom-typography: mat-typography-config(
//   $font-family: '"Open Sans", sans-serif',
// );



// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$crm-app-primary: mat.define-palette(mat.$blue-palette, 900);
$crm-app-accent: mat.define-palette($ecotel-orange, 500);

// The warn palette is optional (defaults to red).
$crm-app-warn: mat.define-palette(mat.$red-palette);

$crm-app-dark-primary: mat.define-palette(mat.$blue-palette, 900);
$crm-app-dark-accent: mat.define-palette(mat.$amber-palette, 500);
$crm-app-dark-warn: mat.define-palette(mat.$red-palette);



// Create the theme object (a Sass map containing all of the palettes).
$crm-app-theme: mat.define-light-theme($crm-app-primary, $crm-app-accent, $crm-app-warn);

$crm-app-dark-theme: mat.define-dark-theme($crm-app-primary, $crm-app-accent, $crm-app-warn);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($crm-app-theme);

// @include angular-material-typography ($custom-typography);

/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { margin: 0; font-family: Arial, Helvetica, Sans-serif !important; }

@import 'scss/ecotel';
@import 'scss/variables';
@import 'assets/stylesheets/maticons.scss';
@import 'assets/stylesheets/opensans.scss';

.crm-app-dark-theme {
    @include mat.all-component-themes($crm-app-dark-theme);
}

.mat-navIcon {
    margin-right: 8px;
}

.mat-navIcon-left {
    margin-right: 8px;
}

.mat-navIcon-right {
    margin-left: 8px;
}
.advFormElement {
    // With flex based forms a margin can not be used, use padding instead.
    /*
    padding: 8px;
    @include gt-xs {
        padding: 16px;
    }*/
    padding: 8px;
    @include gt-xs {
        padding: 8px;
    }
}
.advFormElementSm {
    // With flex based forms a margin can not be used, use padding instead.
    /*
    padding: 8px;
    @include gt-xs {
        padding: 16px;
    }*/
    padding: 4px;
    @include gt-xs {
        padding: 4px;
    }
}

.contentContainer {
    //width: 600px;
    padding: 0 16px 16px 16px;
    
    @include gt-xs {
       padding: 0 32px 32px 32px;
    }
    @include gt-sm {
       padding: 0 32px 32px 32px;
    }
    @include gt-md {
       padding: 0 48px 32px 48px;
    }
    @include gt-lg {
       padding: 0 64px 32px 64px;
    }
}

.advCardElement {
    margin: 8px;
    @include gt-xs {
        margin: 16px;
    }
}

.advFwCardElement {
    margin: 8px 0;
    @include gt-xs {
        margin: 16px 0;
    }
}

.advCardElementSm {
   margin: 4px;
   @include gt-xs {
       margin: 8px;
   }
}

.pending-indicator {
    position: absolute;
    width: 100%;
}

.clickable {
    cursor: pointer;
}

.matFabFixedBottomRight {
    bottom: 1rem;
    position: fixed !important;
    right: 2rem;
    transform: translateZ(0)
}

.sectionToolbar {
    overflow: hidden;
}

.sectionToolbarContent {
    // toolbar already includes 16px padding left
    padding-left: 0px;
    
    @include gt-xs {
       padding-left: 16px;
    }
    @include gt-sm {
       padding-left: 16px;
    }
    @include gt-md {
       padding-left: 32px;
    }
    @include gt-lg {
       padding-left: 48px;
    }
    >a {
        color: white;
    }
}

.sectionToolbarContentButton {
    font-size:20px;
}
//.sectionContent {
    //overflow:auto;
    //max-height: calc(100vh - 128px);
    //@media (max-width: 599px) {
    //   max-height: calc(100vh - 112px);
    //}
    //background-color: blue;
//}


.mat-toolbar-frs, .frs {
    flex: 1 1 auto;
}

.contHead {
    font-weight: 700;
}

.vspace-s {
    height: 16px;
}

.vspace-xs {
    height: 8px;
}

.vspace {
    height: 2em;
}

.right {
    text-align: right;
}

.left {
    text-align: left;
}

.top {
    vertical-align: top;
}


.router-link-active {
    //background-color:#f0f0f0;
    background-color: rgb(255, 193, 7) !important;
}

.full-width-input {
    width: 100%;
    //width: 90%;
    //font-size: 12px;
}

.nintypercent-width-input {
    width: 90%;
}

a {
    color:mat.get-color-from-palette($crm-app-accent);
}

.user-profile-dialog-container .mat-dialog-container{
    overflow-x: hidden !important;
}

.user-profile-dialog-container .mat-form-field-appearance-outline .mat-form-field-label{
    line-height: 1.3;
}

.customer-profile-dialog-container .mat-dialog-container{
    overflow-x: hidden !important;
}

.customer-profile-dialog-container .mat-form-field-appearance-outline .mat-form-field-label{
    line-height: 1.3;
}

span.button-space {
    margin: 0 8px;
}

span.button-space-s {
   margin: 0 2px 0 2px;
}

.fillSpace {
    flex: 1 1 auto;
}

.new-contract-journey-options-class .mat-expansion-panel-body {
    flex-direction: column !important;
    box-sizing: border-box;
    display: flex;
}
/*
.mat-input-element:disabled, .mat-select-disabled .mat-select-value {
    color: black;
}
*/

mat-radio-group mat-error {
    font-size: 75%;
}

.full-width-input {
    width: 100%;
}

.mat-form-field-appearance-standard .mat-form-field-flex {
    padding-top: 0 !important;
}

.mat-form-field {
    line-height: 1em !important;
}

.row-container {
    margin-bottom: 1em;
    max-width:100%;
    @include gt-mx{
        max-width:73%;
    }
}

.infotext-headline {
    color: #001e78;
    font-size: 1.125rem;
    font-weight: bold;
    margin: 0;
    line-height: 23px;
    position: relative;
}

.infotext-small {
    font-size: 0.875em;
}

.light-blue-box {
    border-radius: 10px;
    background-color: #e5e8f1;
}

.btn-submit {
    display: block !important;
    margin: 1em auto !important;
    padding-left: 26px !important;
}

svg {
    fill: currentColor;    
}

.error-card {
    color: #f44336;
    background-color: #fcc;
    margin: 1em 0;
}

// for overlay on sim-register component, EB-17970
.overlay-qr-code-container {
    position: relative !important;
    background-color: white;

}

.impressum {
    color: #002c5c;
    margin-bottom: 0;
    padding: 6px 70px 6px 10px;
    width: auto;
    line-height: 2em;
    max-width: 100vw;
    text-align: left;
    text-decoration: underline;
    cursor: pointer;
    font-size: 1rem !important;
    @media (min-width: 480px) {
        text-align: right;
        margin: 5px 0;
        padding-right: 40px;
        text-align: right;
        vertical-align: top;
        font-weight: 400 !important;
        width: -webkit-fit-content;
        width: fit-content;
        float: right;
        text-decoration: underline;
        margin-bottom: 20px;
    }
}