.app-toolbar-title {
    margin: 0 36px;
    font-size: 24px;
    line-height: 24px;
    font-weight: 500;
}

.note {
    font-size: 12px;
}
